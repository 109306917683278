const bgImg = $('.top-background-img');
const subList = [
    $('.subsite-container'),
    $('.subsite-footer-contact'),
    $('.footer-small'),
    $('.header--subsites')
];
const topBgImg = $('.top-background-img');

console.log($('.header--subsites').outerHeight());

bgImg.css("height",
    subList
        .reduce((acc, cur) => acc + Number(cur.outerHeight()) + 10, 0)
);

topBgImg.css("height", $('.top-image').outerHeight() + $('.top-about').outerHeight());
