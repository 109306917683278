const imgWidth = $('.red-img').width();
const imgHeight = $('.red-img').height();
const background = $('.red-background')

const imgWidthServices = $('.red-img-services').width();
const imgHeightServices = $('.red-img-services').height();
const backgroundServices = $('.red-background-services')
background
    .css("height", imgHeight + 20 + "px")
    .css("width", imgWidth + 20 + "px")
backgroundServices
    .css("height", imgHeightServices + 20 + "px")
    .css("width", imgWidthServices + 20 + "px")
