$(".icons-service-set__item-box")
    .hover(function (e) {
        e.stopPropagation();
        $(e.target).css("transform", "rotate(" + 45 + "deg)");
        $(e.target).parent().find(".icons-service-set__item-text").css("color", "#00000000");
    }, function (e) {
        e.stopPropagation();
        $(e.target).css("transform", "rotate(" + 0 + "deg)");
        $(e.target).parent().find(".icons-service-set__item-text").css("color", "#000000");

    })
